import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';
import { DeletedFranchisesPage } from './deleted-franchises/deleted-franchises.page';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public showMenu: any = false;

  public allStudentInfo: any[] = [];
  public certificate: any;
  public marksheet: any;
  public baseUrl: string = "https://us-central1-aicasindia-in.cloudfunctions.net/app/api/";
  constructor(
    private afs: AngularFirestore,
    public http: HttpClient
  ) {
    this.certificate = localStorage.getItem('certificate') ? localStorage.getItem('certificate') : null;
    this.marksheet = localStorage.getItem('marksheet') ? localStorage.getItem('marksheet') : null;

    this.allStudentInfo = localStorage.getItem('allStudent') ? JSON.parse(localStorage.getItem('allStudent')) : [];
  }

  // getFranchisesData(){
  //   return this.afs.collection('franchises').valueChanges();
  // }
  getFranchisesProfile() {
    let fid = localStorage.getItem('FranchisesId');
    return this.afs.collection('franchises').doc(fid).valueChanges();
  }
  editFranchisesProfile(data) {
    let femail = localStorage.getItem('FranchisesId');
    return this.afs.collection('franchises').doc(femail).set(data);
  }
  deleteFranchisesProfile(email) {
    let femail = localStorage.getItem('FranchisesId');
    return this.afs.collection('franchises').doc(email).delete();
  }
  getStudentsData() {
    let fid = localStorage.getItem('FranchisesId');
    return this.afs.collection('franchises').doc(fid).collection('students').valueChanges();
  }
  saveFranchiseSignupData(signup) {
    return this.afs.collection('franchises').doc(signup.registerNo).set(signup);
  }

  //  getFranchisesLoginData(){
  //   return this.afs.collection('admin_login').valueChanges();
  // }
  saveStudentData(data) {
    let femail = localStorage.getItem('FranchisesId');
    return this.afs.collection('franchises').doc(femail).collection('students').doc(data.id).set(data);
  }

  saveStudentInAll(data) {
    console.log(data)
    return this.afs.collection('all-students').doc(data.id + data.password).set(data);
  }
  deleteStudentInAll(data) {
    return this.afs.collection('all-students').doc(data.id + data.password).delete();
  }

  // getFranchisesInquiry() {
  //   return this.afs.collection('franchisesInquiry').valueChanges();
  // }
  // getStudentsInquiry() {
  //   return this.afs.collection('studentsInquiry').valueChanges();
  // }
  getFranchiseRequest() {
    return this.afs.collection('franchises_request').valueChanges();
  }
  getFranchise(email) {
    //  let femail =localStorage.getItem('FranchisesEmail') ;
    return this.afs.collection('franchises_request').doc(email).valueChanges();
  }
  // //! Franchise Id 
  // getFranchiseId() {
  //   return this.afs.collection('franchise_id').doc('fid').valueChanges();
  // }
  deleteFranchiseInRequest(data) {
    return this.afs.collection('franchises_request').doc(data.email).delete();
  }

  //!add questions
  //!Exam
  // AddQuestion(question) {
  //   // let fid = JSON.parse(localStorage.getItem('profile')).registerNo ;
  //   // console.log(fid);
  //   if (question.language == 'gujarati') {
  //     return this.afs.collection('questions').doc('gujarati').collection('gujarati-questions').doc(question.id.toString()).set(question);
  //   } else {
  //     return this.afs.collection('questions').doc('english').collection('english-questions').doc(question.id.toString()).set(question);
  //   }

  // }

  // editOneStudentExamDetail(data) {
  //   let fid = localStorage.getItem('FranchisesId');
  //   let sid = localStorage.getItem('studentId');
  //   return this.afs.collection('franchises').doc(fid).collection('students').doc(sid).set(data);
  // }

  viewFranchisesProfile() {
    let femail = localStorage.getItem('franchiseEmail');
    return this.afs.collection('franchises_request').doc(femail).valueChanges();
  }

  approveFranchiseSave(data) {
    return this.afs.collection('franchises').doc(data.registerNo).set(data);

  }
  // getQuestions(language) {
  //   if (language == 'gujarati') {
  //     return this.afs.collection('questions').doc('gujarati').collection('gujarati-questions').valueChanges();
  //   } else {
  //     return this.afs.collection('questions').doc('english').collection('english-questions').valueChanges();
  //   }
  // }

  // deleteQuestion(question) {
  //   if (question.language == 'gujarati') {
  //     return this.afs.collection('questions').doc('gujarati').collection('gujarati-questions').doc(question.id.toString()).delete();
  //   } else {
  //     return this.afs.collection('questions').doc('english').collection('english-questions').doc(question.id.toString()).delete();
  //   }
  // }


  // getAllStudentData() {
  //   return this.afs.collection('all-students').valueChanges();
  // }

  editStudentProfile(data) {
    let fid = localStorage.getItem('FranchisesId');
    let sid = localStorage.getItem('studentId');
    return this.afs.collection('franchises').doc(fid).collection('students').doc(sid).set(data);
  }

  DeletedFranchise(data) {
    let fid = localStorage.getItem('FranchisesId');
    //return this.afs.collection('franchises').doc(fid).valueChanges();

  }

  getFranchisesDataInDeletePage() {
    return this.afs.collection('franchises').valueChanges();
  }
  //! //////////////////////////////////////////////////////////////////////////////////////

  getAdminLoginData(data) {
    return this.http.post(this.baseUrl + "admin/admin_login",data);
  }

  getFranchisesData() {
    return this.http.get(this.baseUrl + "franchises/getAllFranchises");
  }
  editFranchises(data) {
    return this.http.post(this.baseUrl + "franchises/update", data);
  }
  deleteFranchises(data) {
    return this.http.post(this.baseUrl + "franchises/deleteFranchises", data);
  }
  registerFranchises(data) {
    return this.http.post(this.baseUrl + "franchises/register", data);
  }

  getAllStudentsByFranchises(data) {
    return this.http.post(this.baseUrl + "students/getStudents_byFranchises", data);
  }

  approveDisapproveNewStudent(data) {
    return this.http.post(this.baseUrl + "students/approve_disapprove_Student", data);
  }
  editStudent(data) {
    return this.http.post(this.baseUrl + "students/update", data);
  }
  approveFranchisesRequest(data){
    return this.http.post(this.baseUrl + "franchises/approveFranchise", data);
  }
  addQuestion(question){
    return this.http.post(this.baseUrl + "questions/add_question", question);
  }
  getQuestion(language){
    return this.http.post(this.baseUrl + "questions/get_question", language);
  }
  editQuestion(data){
    return this.http.post(this.baseUrl + "questions/update_question", data);
  }
  deleteQuestion(data){
    return this.http.post(this.baseUrl + "questions/delete_question", data);
  }
  getResult(data){
    return this.http.post(this.baseUrl + "results/get_result", data); 
  }
  getOneStudentWithExamDetail(data){
    return this.http.post(this.baseUrl+"students/getStudent_byFranchises_withExamDetail",data);
  }
  editResult(data){
    return this.http.post(this.baseUrl + "results/update_result", data); 
  }
  directAddedResult(data){
    return this.http.post(this.baseUrl + "results/directAdded_result", data); 
  }



  //! inquiry
  getFranchisesInquiry() {
    return this.http.get(this.baseUrl + "inquiry_franchises/get_inquiry_franchises"); 
  }
  getStudentsInquiry() {
    return this.http.get(this.baseUrl + "inquiry_students/get_inquiry_students"); 
  }


  //   getAllApprovedStudents() {
  //     return this.http.get(this.baseUrl + "students/get_all_approve_students"); 
  // }

  //!get only length of franchises & student for dashboard
  getLength(){
    return this.http.get(this.baseUrl + "students/get_admin_dashboard"); 
  }
  //!New(null) students pagination
  newStudents(data,pageNo){
    return this.http.post(this.baseUrl+"students/pagination_getNew_students/?page="+pageNo,data); 
  }

  //!Approve students pagination
  approvedStudents(data,pageNo){
    return this.http.post(this.baseUrl+"students/pagination_getApproved_students/?page="+pageNo,data); 
  }
  //!Disapprove students pagination
  disapprovedStudents(data,pageNo){
    return this.http.post(this.baseUrl+"students/pagination_getDisapproved_students/?page="+pageNo,data); 
  }
  //! all franchises pagination
  getAllFranchisesPagination(pageNo){
    return this.http.get(this.baseUrl+"franchises/getAllFranchises_pagination/?page="+pageNo); 
  }
//!get single student
getSingleStudent(data){
  return this.http.post(this.baseUrl+"students/get_single_student",data); 
}
//!get single franchise
getSingleFranchise(data){
  return this.http.post(this.baseUrl+"franchises/get_single_franchise",data); 
}

//!search api for new student
searchNewStudent(data){
  return this.http.post(this.baseUrl + "students/search_new_student", data);
}
//!search api for approve student
searchApproveStudent(data){
  return this.http.post(this.baseUrl + "students/search_approved_student", data);
}
//!search api for disapprove student
searchDisapproveStudent(data){
  return this.http.post(this.baseUrl + "students/search_disapproved_student", data);
}
//!search api for franchises
searchFranchise(data){
  return this.http.post(this.baseUrl + "franchises/search_franchise", data);
}
}
